body {
  margin: 0;
  font-family: 'Noto Serif Kannada', 'Open Sans', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Noto Serif Kannada', 'Open Sans', serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.underline {
  border-bottom-width: 2px;
}

.commonTile {
  @apply w-full h-auto text-left py-2.5;
}

.commonTile_header {
  @apply text-xl text-sea-green font-bold;
}

.commonTile_description {
  @apply text-sm text-grey text-justify leading-loose py-2;
}

.events_image {
  @apply w-full h-96 object-fill;
}

.line-clamp {
  @apply overflow-hidden;
  display : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sub_header {
  @apply text-2xl text-sea-green font-semibold overflow-hidden text-center py-2.5;
}

.sub_header:before, .sub_header:after{
  content: "";
  --tw-bg-opaciy: 1;
  @apply bg-blue-green h-px relative w-6/12 inline-block align-middle;
}

.sub_header:before{
  @apply right-2 -ml-50per;
}

.sub_header:after{
  @apply left-2 -mr-50per;
}


